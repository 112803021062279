// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

document.querySelectorAll("[id^='acwp-toggler-'").forEach((input) => {
    if (sessionStorage.getItem(input.id) === "true" && input.checked !== true) {
        input.click();
    }

    input.addEventListener("change", () => {
        sessionStorage.setItem(input.id, input.checked);
    });
});
