// Get the element you want to add the class to
const element = document.getElementById("page-header");

// Get the element you're using to determine whether the user is at the very top of the page
const pageSpacer = document.getElementById("page-spacer");

// define a new intersection observer. When `entries` matches an element
// where an intersection change occurs, this will fire.
const observer = new IntersectionObserver((entries) => {
    // Convert the array `entries` into a single entry since we only
    // have a singular element on the page to observe
    const [entry] = entries;

    // if this element is intersecting (is not at the top of the page), make it sticky
    if (entry.isIntersecting) {
        element.classList.remove("header-block--sticky");
    } else { // otherwise, make it static
        element.classList.add("header-block--sticky");
    }
});

observer.observe(pageSpacer);
